import { PaletteColor, PaletteColorOptions, alpha, createTheme, darken, getContrastRatio, lighten } from '@mui/material/styles';
import './sass/styles.scss';
declare module '@mui/material/styles' {
  interface CommonColors {
    outline: string;
    outline2: string;
    outline3: string;
    background: string;
    background2: string;
  }
  interface Palette {
    shade0: Palette['primary'];
    shade1: Palette['primary'];
    shade2: Palette['primary'];
    shade3: Palette['primary'];
  }
  interface PaletteOptions {
    shade0: PaletteColorOptions | undefined;
    shade1: PaletteColorOptions | undefined;
    shade2: PaletteColorOptions | undefined;
    shade3: PaletteColorOptions | undefined;
  }
  interface PaletteColor {
    lighter?: string;
    darker?: string;
  }

  interface SimplePaletteColorOptions {
    lighter?: string;
    darker?: string;
  }
  interface TypographyVariants {
    label1?: React.CSSProperties | undefined;
    value1?: React.CSSProperties | undefined;
  }

  interface TypographyVariantsOptions {
    label1?: React.CSSProperties;
    value1?: React.CSSProperties;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    label1: true;
    value1: true;
  }
}

declare module "@mui/material/SvgIcon" {
  interface SvgIconPropsColorOverrides {
    shade0: true;
    shade1: true;
    shade2: true;
    shade3: true;
  }
}

const rgbToHex = (rgba: string, rgb: string = 'rgb(255, 255, 255)'): string => {
  const rgbArray: any = rgb.match(/^rgb?[\s+]?\([\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?,[\s+]?(\d+)[\s+]?/i);
  const rgbaArray: any = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/)
  if (!rgbArray || !rgbaArray) return rgba;
  const red = Math.round((rgbaArray[1] * rgbaArray[4]) + (rgbArray[1] * (1 - rgbaArray[4])));
  const blue = Math.round((rgbaArray[2] * rgbaArray[4]) + (rgbArray[2] * (1 - rgbaArray[4])));
  const green = Math.round((rgbaArray[3] * rgbaArray[4]) + (rgbArray[3] * (1 - rgbaArray[4])));
  return (rgbaArray && rgbaArray.length === 5) ? `#${("0" + red.toString(16)).slice(-2)}${("0" + blue.toString(16)).slice(-2)}${("0" + green.toString(16)).slice(-2)}` : '';
}

const generateShadePalette = (color: string): PaletteColor => {
  const main = rgbToHex(alpha(color, 0.8));
  return {
    lighter: rgbToHex(alpha(color, 0.3)),
    light: rgbToHex(alpha(color, 0.6)),
    main: main,
    dark: color,
    contrastText: getContrastRatio(main, '#fff') > 4.5 ? '#fff' : '#111',
  }
};

const colors = {
  brand: '#005CE5',
  outline: '#D7E0EF',
  outline2: '#DEE5F0',
  outline3: '#DEEEFE',
  background: '#F6F9FD',
  background2: '#F0F7FF',
  error: '#D61B2C',
  shade0: generateShadePalette('#0A2757'),
  shade1: generateShadePalette('#183462'),
  shade2: generateShadePalette('#445C85'),
  shade3: generateShadePalette('#6780A9')
};

const theme = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          fontSize: 16,
          height: 20,
          lineHeight: 20,
          padding: 12,
          '&::placeholder': {
            opacity: 1,
            color: colors.shade0.lighter
          }
        }
      }
    },
    MuiTypography: {
      styleOverrides: {
        root: {

        }
      },
      defaultProps: {
        variantMapping: {
          label1: 'p',
          value1: 'p',
        }
      }
    }
  },
  typography: {
    fontFamily: 'Gotham Rounded SSm',
    h6: {
      color: colors['shade0'].dark,
      fontWeight: 700,
      fontSize: 18,
      lineHeight: 1.2
    },
    subtitle1: {
      color: colors['shade0'].dark,
      fontWeight: 500
    },
    subtitle2: {
      fontFamily: 'Gilroy',
      color: colors['shade2'].main,
      fontWeight: 500
    },
    body1: {
      color: colors['shade0'].dark,
      fontWeight: 500
    },
    body2: {
      fontFamily: 'Gilroy',
      color: colors['shade2'].main,
      fontWeight: 500
    },
    label1: {
      fontFamily: 'Gotham Rounded SSm',
      color: colors['shade0'].main,
      fontWeight: 500,
      fontSize: 12,
      marginBottom: 8
    },
    value1: {
      fontFamily: 'Gotham Rounded SSm',
      color: colors['shade0'].dark,
      fontWeight: 500,
      fontSize: 16
    }
  },
  palette: {
    common: {
      outline: colors.outline,
      outline2: colors.outline2,
      outline3: colors.outline3,
      background: colors.background,
      background2: colors.background2
    },
    primary: {
      light: lighten(colors.brand, 0.2),
      main: colors.brand,
      dark: darken(colors.brand, 0.2)
    },
    error: {
      light: lighten(colors.error, 0.2),
      main: colors.error,
      dark: darken(colors.error, 0.2)
    },
    shade0: colors.shade0,
    shade1: colors.shade1,
    shade2: colors.shade2,
    shade3: colors.shade3
  }
});

export default theme;