import request, { isAxiosError } from '../utils/axios';
import _, { get, toInteger, toString } from 'lodash';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";

export interface IUser {
  id: number;
  email: string;
  fullName: string;
  lastName: string;
  firstName: string;
  middleName: string;
  registrationDate: string;
  birthDate: string;
  nationality: string;
  address: string;
  username: string;
  publicUserId: string;
  mobileNumber: string;
  isOwner: boolean;
  processRegistration: string;
  isLinked: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  informalMerchants: {[key:string]: any}[];
}

export const parseData = (json: any): IUser => {
  return {
    id: toInteger(get(json, 'id')),
    email: toString(get(json, 'email')),
    fullName: toString(get(json, 'fullname')),
    lastName: toString(get(json, 'last_name')),
    firstName: toString(get(json, 'first_name')),
    middleName: toString(get(json, 'middle_name')),
    registrationDate: toString(get(json, 'registration_date')),
    birthDate: toString(get(json, 'birthdate')),
    nationality: toString(get(json, 'nationality')),
    address: toString(get(json, 'address')),
    username: toString(get(json, 'username')),
    publicUserId: toString(get(json, 'public_user_id')),
    mobileNumber: toString(get(json, 'mobile_number')),
    isOwner: !!toInteger(get(json, 'is_owner')),
    processRegistration: toString(get(json, 'process_registration')),
    isLinked: !!toInteger(get(json, 'is_linked')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    informalMerchants: get(json, 'informal_merchants')
  };
}
interface IError {
  message: string;
  status: number;
}

type UserState = {
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: UserState = {
  status: 'idle',
  error: null
};
export const userSlice = createSlice({
  name: 'user',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    }
  },
  extraReducers: builder => { }
});

export const { reset } = userSlice.actions;

export default userSlice.reducer;