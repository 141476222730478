import axios, { AxiosError } from 'axios';
import { reset } from '../store/authSlice';
import { EnhancedStore } from '@reduxjs/toolkit';

const request = axios.create({
  baseURL: process.env.GATSBY_API_BASEURL,
  headers: {
    common: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  }
});

export const setToken = (token: string) => {
  request.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const setBaseUrl = (url: string) => {
  request.defaults.baseURL = url;
}

export const unauthorizedInterceptor = (store: EnhancedStore, callback: Function = () => { }) => {
  request.interceptors.response.use(undefined, async err => {
    const error = err.response;
    if (error.status === 401) {
      store.dispatch(reset({}));
      callback();
    }

    return Promise.reject(err);
  });
}

export const isAxiosError = (err: Error | AxiosError) => {
  return axios.isAxiosError(err);
}

export const mock = (success: boolean, response: any = {}, timeout: number = 1000) => {
  return new Promise<any>((resolve, reject) => {
    setTimeout(() => {
      if (success) {
        resolve({ data: response });
      } else {
        reject({ message: 'Error' });
      }
    }, timeout);
  });
}

export default request;