/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

import { navigate } from 'gatsby';

// You can delete this file if you're not using it
export { default as wrapRootElement } from './src/RootWrapper';
export { default as wrapPageElement } from './src/PageWrapper';
export const onRouteUpdate = ({ location }) => {
  if (location.pathname !== '/' && window.sessionStorage.getItem('token') == null)
    navigate('/');
}