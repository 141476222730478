import request, { isAxiosError, mock } from '../utils/axios';
import _, { get, toInteger, toString } from 'lodash';
import { PayloadAction, PrepareAction, createAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { isNil, omitBy } from 'lodash/fp';

type ApplicationStatus = 'Draft' | 'New' | 'Completed' | 'Returned' | 'Resubmitted' | 'Rejected';

interface IMerchant {
  id: number;
  businessName: string;
  address: string;
  agreeTermsConditions: boolean;
  palengkeName: string;
  profession: string;
  portfolioLink: string;
  jobHasLicense: boolean;
  onlineSellerBiz: string;
  onlineSellerStore: string;
  puvVehicle: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  merchantProfileId: number | null;
  monthlyTransactionId: number | null;
  businessValueId: number | null;
  employeeTotalId: number | null;
  countryId: number | null;
  regionId: number | null;
  cityId: number | null;
  barangayId: number | null;
  zipcodeId: number | null;
  createdBy: number | null;
  updatedBy: number | null;
  deletedBy: number | null;
  dateOfRegistration: string;
  applicationRefNo: string;
  approverId: number | null;
  provinceId: number | null;
  referenceIdCounter: string;
  industryId: number | null;
  industrySubcategoryId: number | null;
  latestStatus: {
    id: number;
    applicationStatus: ApplicationStatus;
    applicationRefNo: string;
    reasons: string;
    notes: string;
    draftStep: string;
    updatedAt: string;
    updatedByLast: string;
    updatedByFirst: string;
  } | string;
}

const parseMerchantDetails = (json: any): IMerchant => {
  return {
    id: toInteger(get(json, 'id')),
    businessName: toString(get(json, 'business_name')),
    address: toString(get(json, 'address')),
    agreeTermsConditions: !!toInteger(get(json, 'agree_terms_conditions')),
    palengkeName: toString(get(json, 'palengke_name')),
    profession: toString(get(json, 'profession')),
    portfolioLink: toString(get(json, 'portfolio_link')),
    jobHasLicense: !!toInteger(get(json, 'job_has_license')),
    onlineSellerBiz: toString(get(json, 'online_seller_biz')),
    onlineSellerStore: toString(get(json, 'online_seller_store')),
    puvVehicle: toString(get(json, 'puv_vehicle')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    merchantProfileId: toInteger(get(json, 'merchant_profile_id')) || null,
    monthlyTransactionId: toInteger(get(json, 'monthly_transaction_id')) || null,
    businessValueId: toInteger(get(json, 'business_value_id')) || null,
    employeeTotalId: toInteger(get(json, 'employee_total_id')) || null,
    countryId: toInteger(get(json, 'country_id')) || null,
    regionId: toInteger(get(json, 'region_id')) || null,
    cityId: toInteger(get(json, 'city_id')) || null,
    barangayId: toInteger(get(json, 'barangay_id')) || null,
    zipcodeId: toInteger(get(json, 'zipcode_id')) || null,
    createdBy: toInteger(get(json, 'created_by')) || null,
    updatedBy: toInteger(get(json, 'updated_by')) || null,
    deletedBy: toInteger(get(json, 'deletedBy')) || null,
    dateOfRegistration: toString(get(json, 'date_of_registration')),
    applicationRefNo: toString(get(json, 'application_ref_no')),
    approverId: toInteger(get(json, 'approver_id')) || null,
    provinceId: toInteger(get(json, 'province_id')) || null,
    referenceIdCounter: toString(get(json, 'reference_id_counter')),
    industryId: toInteger(get(json, 'industry_id')) || null,
    industrySubcategoryId: toInteger(get(json, 'industry_subcategory_id')) || null,
    latestStatus: {
      id: toInteger(get(json, 'latest_status.0.id')),
      applicationStatus: toString(get(json, 'latest_status.0.application_status')) as ApplicationStatus,
      applicationRefNo: toString(get(json, 'latest_status.0.application_ref_no')),
      reasons: toString(get(json, 'latest_status.0.reasons')),
      notes: toString(get(json, 'latest_status.0.notes')),
      draftStep: toString(get(json, 'latest_status.0.draft_step')),
      updatedAt: toString(get(json, 'latest_status.0.updated_at')),
      updatedByLast: toString(get(json, 'latest_status.0.updated_by_last')),
      updatedByFirst: toString(get(json, 'latest_status.0.updated_by_first'))
    }
  };
}
interface IError {
  message: string;
  status: number;
}

interface IProfile {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  industryId: number;
  industrySubcategoryId: number;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

const parseProfileData = (json: any): IProfile => {
  return {
    id: toInteger(get(json, 'id')),
    name: toString(get(json, 'name')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    industryId: toInteger(get(json, 'industry_id')),
    industrySubcategoryId: toInteger(get(json, 'industry_subcategory_id')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  }
}

export const fetchProfiles = createAsyncThunk<IProfile[], void, { rejectValue: IError }>(
  'merchant/fetchProfiles',
  async (args, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/merchant-profiles');
      return data
        .map((profile: { [key: string]: any }) => parseProfileData(profile))
        // hide professional option
        .filter((profile: IProfile) => profile.id !== 3);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export interface IDocumentType {
  id: number;
  appType: string;
  name: string;
  aliasName: string;
  subtypes?: IDocumentType[];
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

const parseDocumentTypeData = (json: any): IDocumentType => {
  return {
    id: toInteger(get(json, 'id')),
    appType: toString(get(json, 'app_type')),
    name: toString(get(json, 'name')),
    aliasName: toString(get(json, 'alias_name')),
    subtypes: ((subtypes: { [key: string]: any }[]) => Array.isArray(subtypes) ?
      subtypes.map(subtype => parseDocumentTypeData(subtype)) :
      undefined)(get(json, 'subtypes')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  }
}

export const fetchDocumentTypesByProfile = createAsyncThunk<IDocumentType[], string | number | undefined, { rejectValue: IError }>(
  'merchant/fetchDocumentTypesByProfile',
  async (profile: string | number | undefined, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/document-types', { params: { app_type: 'informal', alias_name: profile } });
      const documentTypes = data.map((type: { [key: string]: any }) => parseDocumentTypeData(type));
      return documentTypes.find((type: IDocumentType) => type.aliasName == profile)?.subtypes || [];

    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);
interface IMonthlyTransaction {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

const parseMonthlyTransactionData = (json: any): IMonthlyTransaction => {
  return {
    id: toInteger(get(json, 'id')),
    name: toString(get(json, 'name')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  }
}

export const fetchMonthlyTransactions = createAsyncThunk<IMonthlyTransaction[], void, { rejectValue: IError }>(
  'merchant/fetchMonthlyTransactions',
  async (args, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/monthly-transactions');
      return data.map((transaction: { [key: string]: any }) => parseMonthlyTransactionData(transaction));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface IEmployeeTotal {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

const parseEmployeeTotalData = (json: any): IEmployeeTotal => {
  return {
    id: toInteger(get(json, 'id')),
    name: toString(get(json, 'name')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  }
}

export const fetchEmployeeTotals = createAsyncThunk<IEmployeeTotal[], void, { rejectValue: IError }>(
  'merchant/fetchEmployeeTotals',
  async (args, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/employee-totals');
      return data.map((total: { [key: string]: any }) => parseEmployeeTotalData(total));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

const composeMerchantDetails = (json: IMerchant): { [key: string]: any } => {
  return {
    user_id: toInteger(get(json, 'userId')) || null,
    merchant_id: toInteger(get(json, 'id')) || null,
    latest_status: toString(get(json, 'latestStatus')),
    business_name: toString(get(json, 'businessName')),
    address: toString(get(json, 'address')),
    agree_terms_conditions: !!toInteger(get(json, 'agreeTermsConditions')),
    palengke_name: toString(get(json, 'palengkeName')),
    profession: toString(get(json, 'profession')),
    portfolio_link: toString(get(json, 'portfolioLink')),
    job_has_license: !!toInteger(get(json, 'jobHasLicense')),
    online_seller_biz: toString(get(json, 'onlineSellerBiz')),
    online_seller_store: toString(get(json, 'onlineSellerStore')),
    puv_vehicle: toString(get(json, 'puvVehicle')),
    merchant_profile_id: toInteger(get(json, 'merchantProfileId')) || null,
    monthly_transaction_id: toInteger(get(json, 'monthlyTransactionId')) || null,
    business_value_id: toInteger(get(json, 'businessValueId')) || null,
    employee_total_id: toInteger(get(json, 'employeeTotalId')) || null,
    country_id: toInteger(get(json, 'countryId')) || null,
    region_id: toInteger(get(json, 'regionId')) || null,
    city_id: toInteger(get(json, 'cityId')) || null,
    barangay_id: toInteger(get(json, 'barangayId')) || null,
    zipcode_id: toInteger(get(json, 'zipcodeId')) || null,
    date_of_registration: toString(get(json, 'dateOfRegistration')),
    application_ref_no: toString(get(json, 'applicationRefNo')),
    approver_id: toInteger(get(json, 'approverId')) || null,
    province_id: toInteger(get(json, 'provinceId')) || null,
    reference_id_counter: toString(get(json, 'referenceIdCounter')),
    industry_id: toInteger(get(json, 'industryId')) || null,
    industry_subcategory_id: toInteger(get(json, 'industrySubcategoryId')) || null
  };
}

interface ISaveMerchant extends IMerchant {
  userId: number;
}

export const saveMerchantDetails = createAsyncThunk<IMerchant, ISaveMerchant, { rejectValue: IError }>(
  'merchant/saveMerchantDetails',
  async ({ userId: user_id, ...merchant }, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.post('/api/informal/informal-merchants', { user_id, ...omitBy(isNil, composeMerchantDetails(merchant)) });
      return parseMerchantDetails(data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const getMerchantDetails = createAsyncThunk<IMerchant, number, { rejectValue: IError }>(
  'merchant/getMerchantDetails',
  async (merchantId: number, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get(`/api/informal/informal-merchants/${merchantId}`);
      return parseMerchantDetails(data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

const composeMerchantDocument = async (document: IDocument) => {
  const response: Response = await fetch(document.path);
  const type = response.headers.get('content-type');
  const blob: BlobPart = await response.blob();
  const file = new File([blob], document.fileName, { ...(!!type && { type }) });
  return { name: `${document.aliasName}[]`, file };
}

export const saveMerchantDocuments = createAsyncThunk<{ [message: string]: string }, { merchantId: number, documents: IDocument[] }, { rejectValue: IError }>(
  'merchant/saveMerchantDocuments',
  async ({ merchantId, documents }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('informal_merchant_id', `${merchantId}`);

      for (const document of documents) {
        const { name, file } = await composeMerchantDocument(document);
        formData.append(name, file);
      }

      const { data: { data } } = await request.post('/api/informal/informal-documents', formData);
      return data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

const parseMerchantDocument = (json: any): IDocument => {
  return {
    id: toInteger(get(json, 'document_id')),
    typeId: toInteger(get(json, 'document_type_id')),
    subTypeId: toInteger(get(json, 'document_subtype_id')),
    fileName: toString(get(json, 'original_filename')),
    path: toString(get(json, 'path')),
    mimeType: toString(get(json, 'mime_type')),
    s3Filename: toString(get(json, 's3_filename')),
  };
}

export const fetchMerchantDocuments = createAsyncThunk<IDocument[], number, { rejectValue: IError }>(
  'merchant/fetchMerchantDocuments',
  async (merchantId, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/informal-documents', { params: { informal_merchant_id: merchantId } });
      const documents = Object.entries(data)
        .flatMap((type: [string, any]): { [key: string]: any } => type.at(1))
        .flatMap(type => Object.entries(type).flatMap((subType: [string, any]): { [key: string]: any } => subType.at(1)));

      return documents.map(document => parseMerchantDocument(document));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface IBusinessValue {
  id: number;
  name: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

const parseBusinessValueData = (json: any): IBusinessValue => {
  return {
    id: toInteger(get(json, 'id')),
    name: toString(get(json, 'name')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  }
}

export const fetchBusinessValues = createAsyncThunk<IBusinessValue[], void, { rejectValue: IError }>(
  'merchant/fetchBusinessValues',
  async (args, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/business-values');
      return data.map((value: { [key: string]: any }) => parseBusinessValueData(value));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export interface IDocument {
  id: number;
  typeId: number;
  subTypeId: number;
  fileName: string;
  path: string;
  aliasName?: string;
  mimeType?: string;
  s3Filename?: string;
}

type MerchantState = {
  details: IMerchant | null;
  documents: IDocument[];
  profiles: IProfile[],
  monthlyTransactions: IMonthlyTransaction[],
  documentTypes: IDocumentType[],
  employeeTotals: IEmployeeTotal[],
  businessValues: IBusinessValue[],
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: MerchantState = {
  details: null,
  documents: [],
  profiles: [],
  monthlyTransactions: [],
  documentTypes: [],
  employeeTotals: [],
  businessValues: [],
  status: 'idle',
  error: null
};

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    },
    setDetails: (state, action: PayloadAction<any>) => {
      return { ...state, details: { ...state.details, ...action.payload } };
    },
    setDocuments: (state, action: PayloadAction<any[]>) => {
      const documents = [...state.documents];
      action.payload.forEach(document => {
        if (!documents.find(({ id }) => document.id == id))
          documents.push(document);
      })
      return { ...state, documents };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchProfiles.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchProfiles.fulfilled, (state, { payload }) => {
      state.profiles = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchProfiles.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(getMerchantDetails.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getMerchantDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getMerchantDetails.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });


    builder.addCase(fetchMerchantDocuments.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchMerchantDocuments.fulfilled, (state, { payload }) => {
      state.documents = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchMerchantDocuments.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });


    builder.addCase(saveMerchantDetails.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(saveMerchantDetails.fulfilled, (state, { payload }) => {
      state.details = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(saveMerchantDetails.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchDocumentTypesByProfile.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchDocumentTypesByProfile.fulfilled, (state, { payload }) => {
      state.documentTypes = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchDocumentTypesByProfile.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchMonthlyTransactions.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchMonthlyTransactions.fulfilled, (state, { payload }) => {
      state.monthlyTransactions = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchMonthlyTransactions.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchEmployeeTotals.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchEmployeeTotals.fulfilled, (state, { payload }) => {
      state.employeeTotals = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchEmployeeTotals.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchBusinessValues.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchBusinessValues.fulfilled, (state, { payload }) => {
      state.businessValues = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchBusinessValues.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  }
});

export const { reset, setDetails, setDocuments } = merchantSlice.actions;

export default merchantSlice.reducer;