import { combineReducers } from 'redux';
import authReducer from './authSlice';
import locationReducer from './locationSlice';
import userReducer from './userSlice';
import configReducer from './configSlice';
import merchantReducer from './merchantSlice';

const rootReducer = combineReducers({
  config: configReducer,
  auth: authReducer,
  user: userReducer,
  merchant: merchantReducer,
  location: locationReducer
});

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer;