import React from "react";
import { Helmet } from "react-helmet";
import Bridge from "./bridge";

const bridgeUrl = "https://appx/web-view.min.js";
const BridgeContext = React.createContext<Bridge | null>(null);
const BridgeProvider: React.FC<any> = ({ children }) => {
  const [isBridgeReady, setIsBridgeReady] = React.useState(false);
  const handleBridgeOnLoadOnError = () => setIsBridgeReady(true);
  return (
    <React.Fragment>
      <Helmet onChangeClientState={(newState, addedTags, removedTags) => {
        const script = addedTags.scriptTags?.find(({ src }) => src == bridgeUrl);
        ['load', 'error'].forEach(event => script?.addEventListener(event, handleBridgeOnLoadOnError, false));
      }}>
        <script src={bridgeUrl} type="text/javascript"></script>
      </Helmet>
      {!!isBridgeReady && <BridgeContext.Provider value={Bridge.isAvailable() ? Bridge.instance : null}> {children}</BridgeContext.Provider>}
    </React.Fragment>
  )

}

export const useBridgeContext = () => React.useContext(BridgeContext) as Bridge;
export default BridgeProvider;