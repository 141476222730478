import Events from "./utils/events";

type BridgeResponse = {
  action: string,
  payload: any
};

export default class Bridge extends Events {
  private static _instance?: Bridge;

  private constructor() {
    super();

    if (Bridge._instance) throw new Error("Use Bridge.instance instead of new.");

    Bridge._instance = this;

    my.onMessage = this.read.bind(this);

  }

  static get instance() {
    return Bridge._instance ?? (Bridge._instance = new Bridge());
  }

  static isAvailable() {
    return typeof my != 'undefined';
  }

  public send(command: string, options?: any) {
    my.postMessage({ command, options });
  }

  private read(response: BridgeResponse) {
    this.trigger(response.action, response.payload);
  }
}