import React from "react"
import { ThemeProvider } from '@mui/system';
import theme from "../theme";
import PropTypes from "prop-types";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { useAppSelector } from "../store/hooks";
import { some } from "lodash/fp";

interface LayoutProps extends React.PropsWithChildren {
  type?: string;
}

const Layout: React.FC<LayoutProps> = ({ children, type = 'default' }) => {
  const auth = useAppSelector(state => state.auth);
  const merchant = useAppSelector(state => state.merchant);
  const [isLoading, setIsLoading] = React.useState(false);

  const statuses = [auth.status, merchant.status];
  React.useEffect(() => {
    setIsLoading(some(status => status == 'loading', statuses));
  }, statuses);

  return (
    <ThemeProvider theme={theme} >
      <Box sx={{ height: '100vh' }}>{children}</Box>
      <Backdrop sx={theme => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })} open={isLoading}>
        <CircularProgress size={100} color="inherit" />
      </Backdrop>
    </ThemeProvider>
  )
}

Layout.propTypes = {
  type: PropTypes.string
}

export default Layout;