import { configureStore } from "@reduxjs/toolkit";
import { authTokenListener } from "./authSlice";
import rootReducer from "./reducers";
import { setConfigListerner } from "./configSlice";

// middleware
const middleware = [authTokenListener.middleware, setConfigListerner.middleware];

// creating store
const createStore = () => configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
  devTools: process.env.NODE_ENV !== 'production'
});


type ConfiguredStore = ReturnType<typeof createStore>
type StoreGetState = ConfiguredStore["getState"]
export type RootState = ReturnType<StoreGetState>
export type AppDispatch = ConfiguredStore["dispatch"]

export default createStore