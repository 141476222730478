export default class Events {
  private _events: { [key: string]: Function[] }

  constructor() {
    this._events = {};
  }

  private get events() {
    return this._events;
  }

  on(event: string, callback: Function) {
    var handlers = this.events[event] || [];
    handlers.push(callback);
    this.events[event] = handlers;
  }

  off(event: string) {
    delete this.events[event];
  }

  trigger(event: string, data: any) {
    var handlers = this.events[event];

    if (!handlers || handlers.length < 1) return;

    [].forEach.call(handlers, function (handler: Function) {
      handler(data);
    });
  }
}