import request, { setToken, isAxiosError } from '../utils/axios';
import _, { get, toInteger, toString } from 'lodash';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { IUser, parseData as parseUserData } from './userSlice';
import { listenerMiddleware } from './listenerMiddleware';
import { RootState } from '.';

interface IError {
  message: string;
  status: number;
}

interface PortalTokenPayload {
  grant_type: string;
  client_id: string;
  client_secret: string;
}

export const getPortalToken = createAsyncThunk<{ [key: string]: any }, PortalTokenPayload, { rejectValue: IError }>(
  'auth/getPortalToken',
  async (payload: PortalTokenPayload, { rejectWithValue }) => {
    const formData = new FormData();

    Object.entries(payload).forEach(([key, value]) => formData.append(key, value as any));

    try {
      const response = await request.post('/oauth/token', formData);
      return response.data;
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface GcashProfilePayload {
  grant_type: "AUTHORIZATION_CODE" | "REFRESH_TOKEN";
  auth_code?: string;
  refresh_token?: string;
}

interface IGcashProfile {
  userId: number;
  publicUserId: string;
  isRegistered: boolean;
  firstName: string;
  lastName: string;
  mobileNumber: string;
  emailAddress: string;
}

const parseGcashProfileData = (json: any): IGcashProfile => {
  return {
    userId: toInteger(get(json, 'user_id')),
    publicUserId: toString(get(json, 'data.userId')),
    isRegistered: !!toString(get(json, 'is_registered')),
    firstName: toString(get(json, 'data.basicInfo.firstName')),
    lastName: toString(get(json, 'data.basicInfo.lastName')),
    mobileNumber: toString(get(json, 'data.basicInfo.mobileNumber')),
    emailAddress: toString(get(json, 'data.basicInfo.emailAddress')),
  }
}

export const getGCashProfileByAuthCode = createAsyncThunk<IGcashProfile, GcashProfilePayload, { rejectValue: IError }>(
  'auth/getGCashProfileByAuthCode',
  async (payload: GcashProfilePayload, { rejectWithValue }) => {
    try {
      const { data } = await request.post('/api/informal/profile-linking', payload);
      return parseGcashProfileData(data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface ILoginPayload {
  mobile_number: string;
  public_user_id: string;
  user_id: number;
}

interface ILogin {
  accessToken: string;
  authenticatedUser: IUser;
}

const parseLoginData = (json: any): ILogin => {
  return {
    accessToken: toString(get(json, 'access_token')),
    authenticatedUser: parseUserData(get(json, 'authenticated_user'))
  }
}

export const login = createAsyncThunk<ILogin, ILoginPayload, { rejectValue: IError }>(
  'auth/login',
  async (payload: ILoginPayload, { rejectWithValue }) => {
    try {
      const { data } = await request.post('/api/informal/auto-login', payload);
      return parseLoginData(data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

interface IRegisterPayload {
  mobile_number: string;
  public_user_id: string;
  first_name: string;
  last_name: string;
  email: string;
}

export const register = createAsyncThunk<ILogin, IRegisterPayload, { rejectValue: IError }>(
  'auth/register',
  async (payload: IRegisterPayload, { rejectWithValue }) => {
    try {
      const { data } = await request.post('/api/informal/auto-registration', payload);
      return parseLoginData(data);
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type AuthState = {
  token: string | null;
  gcashProfile: IGcashProfile | null;
  user: IUser | null;
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: AuthState = {
  token: null,
  gcashProfile: null,
  user: null,
  status: 'idle',
  error: null
};
export const authSlice = createSlice({
  name: 'auth',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(getPortalToken.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getPortalToken.fulfilled, (state, { payload }) => {
      state.token = payload.access_token;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getPortalToken.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(getGCashProfileByAuthCode.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(getGCashProfileByAuthCode.fulfilled, (state, { payload }) => {
      state.gcashProfile = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(getGCashProfileByAuthCode.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(login.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(login.fulfilled, (state, { payload }) => {
      state.token = payload.accessToken;
      state.user = payload.authenticatedUser;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(login.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(register.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.token = payload.accessToken;
      state.user = payload.authenticatedUser;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  }
});

export const { reset } = authSlice.actions;
export const authTokenListener = listenerMiddleware;

authTokenListener.startListening({
  matcher: isAnyOf(getPortalToken.fulfilled, login.fulfilled, register.fulfilled),
  effect: async (action, listenerApi) => {
    const { auth } = listenerApi.getState() as RootState;
    setToken(auth.token!);
    window.sessionStorage.setItem('token', auth.token!);
  },
});

export default authSlice.reducer;