import request, { isAxiosError, setBaseUrl } from '../utils/axios';
import _, { get, toInteger, toString } from 'lodash';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { listenerMiddleware } from './listenerMiddleware';
import { RootState } from '.';

type Envs = "development" | "production";

export interface IConfig {
  apiBaseUrl: string;
  authCode: string;
  client: { id: string, secret: string }
  env: Envs,
  user?: { userId: number, publicUserId: string, mobileNumber: string }
}

export const parseData = (json: any): IConfig => {
  const config: IConfig = {
    apiBaseUrl: toString(get(json, 'apiBaseUrl')),
    authCode: toString(get(json, 'authCode')),
    client: {
      id: toString(get(json, 'client.id')),
      secret: toString(get(json, 'client.secret'))
    },
    env: toString(get(json, 'env')) as Envs
  };
  if ('user' in json) {
    config.user = {
      userId: toInteger(get(json, 'user.id')),
      publicUserId: toString(get(json, 'user.publicId')),
      mobileNumber: toString(get(json, 'user.mobileNumber')),
    };
  }

  return config;
}

interface IError {
  message: string;
  status: number;
}

type ConfigState = {
  debug: boolean;
  data: IConfig | null;
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: ConfigState = {
  debug: true,
  data: null,
  status: 'idle',
  error: null
};
export const configSlice = createSlice({
  name: 'config',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    },
    setConfig: (state, { payload }: PayloadAction<any>) => {
      return { ...initialState, debug: payload.env == 'development', data: parseData(payload) };
    }
  },
  extraReducers: builder => { }
});

export const { reset, setConfig } = configSlice.actions;
export const setConfigListerner = listenerMiddleware;

setConfigListerner.startListening({
  type: 'config/setConfig',
  effect: async (action, listenerApi) => {
    const { config } = listenerApi.getState() as RootState;
    setBaseUrl(config.data?.apiBaseUrl!);
  },
});

export default configSlice.reducer;