import request, { isAxiosError } from '../utils/axios';
import _, { get, toInteger, toString } from 'lodash';
import { PayloadAction, createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { lowerCase, startCase } from 'lodash/fp';

interface IError {
  message: string;
  status: number;
}

interface ILocation {
  id: number;
  code: string;
  parent: string;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
  createdBy: number;
  updatedBy: number;
  deletedBy: number;
}

interface LocationPayload {
  address_type: string;
  parent_id_code: string;
}

const parseData = (json: any): ILocation => {
  return {
    id: toInteger(get(json, 'id')),
    code: toString(get(json, 'id_code')),
    parent: toString(get(json, 'parent_id_code')),
    name: startCase(lowerCase(toString(get(json, 'name')))),
    type: toString(get(json, 'address_type')),
    createdAt: toString(get(json, 'created_at')),
    updatedAt: toString(get(json, 'updated_at')),
    deletedAt: toString(get(json, 'deleted_at')),
    createdBy: toInteger(get(json, 'created_by')),
    updatedBy: toInteger(get(json, 'updated_by')),
    deletedBy: toInteger(get(json, 'deleted_by'))
  };
}

export const fetchRegions = createAsyncThunk<ILocation[], string, { rejectValue: IError }>(
  'location/fetchRegions',
  async (parent_id_code: string, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/kyc-addresses', { params: { address_type: 'REGION', parent_id_code } });
      return data.map((location: { [key: string]: any }) => parseData(location));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const fetchProvinces = createAsyncThunk<ILocation[], string | undefined, { rejectValue: IError }>(
  'location/fetchProvinces',
  async (parent_id_code: string | undefined, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/kyc-addresses', { params: { address_type: 'PROVINCE', parent_id_code } });
      return data.map((location: { [key: string]: any }) => parseData(location));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const fetchCitiesMunicipalities = createAsyncThunk<ILocation[], string, { rejectValue: IError }>(
  'location/fetchCitiesMunicipalities',
  async (parent_id_code: string, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/kyc-addresses', { params: { address_type: 'CITY', parent_id_code } });
      return data.map((location: { [key: string]: any }) => parseData(location));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const fetchBarangays = createAsyncThunk<ILocation[], string, { rejectValue: IError }>(
  'location/fetchBarangays',
  async (parent_id_code: string, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/kyc-addresses', { params: { address_type: 'TOWN', parent_id_code } });
      return data.map((location: { [key: string]: any }) => parseData(location));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

export const fetchZipcodes = createAsyncThunk<ILocation[], string, { rejectValue: IError }>(
  'location/fetchZipcodes',
  async (parent_id_code: string, { rejectWithValue }) => {
    try {
      const { data: { data } } = await request.get('/api/informal/kyc-addresses', { params: { address_type: 'ZIPCODE', parent_id_code } });
      return data.map((location: { [key: string]: any }) => parseData(location));
    } catch (error: any) {
      if (isAxiosError(error)) {
        const { response: { data, status } } = error;
        return rejectWithValue({ message: data.message, status });
      } else {
        return rejectWithValue({ message: error.message, status: 0 });
      }
    }
  }
);

type LocationState = {
  regions: ILocation[] | null;
  provinces: ILocation[] | null;
  citiesMunicipalities: ILocation[] | null;
  barangays: ILocation[] | null;
  zipcodes: ILocation[] | null;
  status: 'loading' | 'idle';
  error: string | null;
}

const initialState: LocationState = {
  regions: [],
  provinces: [],
  citiesMunicipalities: [],
  barangays: [],
  zipcodes: [],
  status: 'idle',
  error: null
};
export const locationSlice = createSlice({
  name: 'location',
  initialState: { ...initialState },
  reducers: {
    reset: (state, action: PayloadAction<any>) => {
      return { ...initialState };
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchRegions.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchRegions.fulfilled, (state, { payload }) => {
      state.regions = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchRegions.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchProvinces.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchProvinces.fulfilled, (state, { payload }) => {
      state.provinces = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchProvinces.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchCitiesMunicipalities.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchCitiesMunicipalities.fulfilled, (state, { payload }) => {
      state.citiesMunicipalities = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchCitiesMunicipalities.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchBarangays.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchBarangays.fulfilled, (state, { payload }) => {
      state.barangays = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchBarangays.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });

    builder.addCase(fetchZipcodes.pending, state => {
      state.status = "loading";
      state.error = null;
    });
    builder.addCase(fetchZipcodes.fulfilled, (state, { payload }) => {
      state.zipcodes = payload;
      state.status = "idle";
      state.error = null;
    });
    builder.addCase(fetchZipcodes.rejected, (state, { payload }) => {
      if (!!payload) state.error = payload.message;
      state.status = "idle";
    });
  }
});

export const { reset } = locationSlice.actions;

export default locationSlice.reducer;